// SYSTEM
import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

//ADVANCED
import { ConfigProvider, Layout } from 'antd';
import locale from 'antd/lib/locale/ru_RU';

//SCRIPTS
import { postRequest } from './Scripts/postRequest.js';
import { pushNotif } from './Scripts/pushNotif.js';
import { getCookie, setCookie, delCookie } from './Scripts/cookieManager.js';

// STYLE
import 'antd/dist/antd.css';
import './css/App.css';
import './css/Keyframes.css';

// PANELS
const Auth = lazy(() => import('./components/Auth/Auth'));
const Hello = lazy(() => import('./components/Panels/Hello'));
const Chats = lazy(() => import('./components/Panels/Chats'));
const Users = lazy(() => import('./components/Panels/Users'));
const Settings = lazy(() => import('./components/Panels/Settings'));
const ExitPage = lazy(() => import('./components/Auth/Exit'));
const Error404 = lazy(() => import('./components/Errors/404'));

class App extends Component {
  constructor(props) {
		super(props);

    this.state = {
      auth: 0,
      profile: {},
      error: ['',''],
      loading: true,
      moreload: true,
      modal: '',
      panel: [],
      longpool: 'https://chat.clefer.ru/api/longpool/index.php',
      api: 'https://chat.clefer.ru/api/index.php'
    };
  }

  //* Вызов сразу после рендера
  async componentDidMount() {
    if (getCookie('token') !== undefined) {
      await this.login(1,1,getCookie('token'));
    } else {
      await this.setState( { auth:1 } );
    }
  }

  //* Вход
  async login (l,p,t) {
    await this.setState( { auth: 0 } );
    let user = { login: l, pass: p, method: 'auth' };
    if (t) {
      user = { token: t, method: 'auth' };
    }
    
    await postRequest(`${this.state.api}`, user)
    .then(async json => {
      var data = json;
      if(data.code) {
        let profile = ( data.profile ) ? data.profile : {};
        
        await this.setState( {
          profile: profile
        } );
  
        if (!t) {
          setCookie('token', data.profile.token);
          window.location.href = '/';
        }

        await this.setState( {
          auth:2,
          loading: false
        } );

      }
      if(data.error) {
        await this.setState( { auth:1, error: data.error } );
        pushNotif('error',`${data.error[0]}`,`${data.error[1].length > 0 ? `${data.error[1]}`:''}`);
        if (data.error[0] === 'Неверный логин или пароль') {
          await delCookie('token');
          window.location.href = '/';
          await this.setState( {auth: 1, error: ['','']} );
        }
      }
    })
    .catch( async err => {
      await this.setState( { auth:1, error: ['Проблемы со связью',''] } );	//Проблемы со связью
    });
  }

  //* Получение информации
  async api(v,s) {
    if (s) {
      await this.setState( { loading: true, moreload:true, modal:'' } );
    } else {
      await this.setState({ moreload:true });
    }
    
    v['token'] = getCookie('token');
    await postRequest(`${this.state.api}`, v)
    .then(async json => {
      var data = json;
      if(data.code) {
        let profile          = ( data.profile ) ? data.profile : {};

        await this.setState( {
          profile: profile
        } );

        //* Методы
        switch(v['method']) {

          default: break;
        }
      }
      if(data.error) {
        await this.setState( { error: data.error } );
        pushNotif('error',`${data.error[0]}`,`${data.error[1].length > 0 ? `${data.error[1]}`:''}`);
        if (data.error[0] === 'Неверный логин или пароль') {
          await delCookie('token');
          window.location.href = '/';
          await this.setState( {auth: 1, error: ['','']} );
        }
      }
    })
    .catch( async err => {
      await this.setState( { error: ['Проблемы со связью',''] } );	//Проблемы со связью
    });
    if (s) {
      await this.setState( { loading: false, moreload: false } );
    } else {
      await this.setState({ moreload:false });
    }
  }

  //* Модальные окна
  async modal(t) {
    await this.setState( { modal:t } );
  }

  //* Модальные окна
  async panel(t) {
    var panels = this.state.panel;
    if (panels.indexOf( t ) != -1) {
      var index = panels.indexOf(t);
      if (index > -1) panels.splice(index, 1);
    } else {
      await panels.push(t);
    }
    await this.setState( { panel:panels } );
  }

  render() {
    const spin = <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>;
    const f_Login = (v) => { this.login(v['username'],v['password']); };

    return (
      <ConfigProvider locale={locale}>
        <Layout className="layout-global">
          <BrowserRouter>
            <Suspense fallback={spin}>
              <Routes>
                <Route path="*" element={
                  <Error404 />
                } />
                <Route path="/login" element={
                  <Auth state={this.state} f_Login={f_Login} />
                } />
                <Route path="/" element={
                  <Hello state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} />
                } />
                <Route path="/chats" element={
                  <Chats state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} />
                } />
                <Route path="/users" element={
                  <Users state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} />
                } />
                <Route path="/settings" element={
                  <Settings state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} />
                } />
                <Route path='/exit' element={
                  <ExitPage state={this.state} setState={this.setState.bind(this)} />
                } />
              </Routes> 
            </Suspense>
          </BrowserRouter>
        </Layout>
      </ConfigProvider>
    );
  }
}

export default App;
