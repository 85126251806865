export const getCookie = function (name) {
    let matches = document.cookie.match(new RegExp( "(?:^|; )" + name.replace(/([$?*|{}\]\\^])/g, '\\$1') + "=([^;]*)" ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const setCookie = function (name, value, options = {}) {
    options = { path: '/', ...options };
    if (options.expires instanceof Date) { options.expires = options.expires.toUTCString(); }
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) { updatedCookie += "=" + optionValue; }
    }
    document.cookie = updatedCookie;
}

export const delCookie = function (name) {
    setCookie(name, "", { 'max-age': -1 })
}